import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";

export const productAutocomplete = (
  clientId: number,
  data: PaginatedRequest
): Promise<
  AxiosResponse<
    PaginatedResponse<{ articleNumber: string; name: string; stock: number }[]>
  >
> =>
  getPaginated(
    `client/${clientId}/product/autocomplete?include_concept=1`,
    data
  );
