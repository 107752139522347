
import Vue from "vue";
import KSelect from "@/components/crud/fields/KSelect.vue";
import { getTranslatedEnumOptions } from "@/application/util/enum";
import { UnitLoad } from "@/modules/delivery/enums";

export default Vue.extend({
  name: "UnitLoadSelect",
  components: { KSelect },
  data: () => ({
    items: getTranslatedEnumOptions(UnitLoad, "delivery.enums.unitLoad"),
  }),
});
