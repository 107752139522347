
import Vue from "vue";
import KForm from "@/components/KForm.vue";
import axios, { AxiosError, AxiosResponse } from "axios";
import eventBus from "@/application/eventBus";
import {
  conceptDeliveryShow,
  conceptSave,
  conceptSaveById,
  DeliveryProduct,
  DeliverySingle,
  send,
  sendById,
} from "@/modules/delivery/api/deliverySave";
import { mapGetters, mapMutations } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import {
  getTranslatedEnumOptions,
  TranslatedEnumOptions,
} from "@/application/util/enum";
import { Unit } from "@/modules/product/enum/Unit";
import KSelect from "@/components/crud/fields/KSelect.vue";
import ProductAutocomplete from "@/modules/delivery/components/ProductAutocomplete.vue";
import UnitLoadSelect from "@/modules/delivery/components/UnitLoadSelect.vue";
import { UnitLoad } from "@/modules/delivery/enums";

interface ComponentData {
  isLoading: boolean;
  values: Partial<DeliverySingle>;
  isLoadingSave: boolean;
  isFormDirty: boolean;
  unitOptions: TranslatedEnumOptions;
  productToAdd: DeliveryProduct | null;
}

export default Vue.extend({
  name: "ConceptDeliveryForm",
  components: {
    UnitLoadSelect,
    ProductAutocomplete,
    KSelect,
    RequiredClientDialog,
    KForm,
    KTextField,
  },
  data: (): ComponentData => ({
    isLoading: true,
    values: {
      enteredBy: {
        name: "",
        email: "",
      },
      items: [],
    },
    isFormDirty: false,
    isLoadingSave: false,
    unitOptions: getTranslatedEnumOptions(Unit, "product.unit"),
    productToAdd: null,
  }),
  computed: {
    ...mapGetters({
      findError: "error/find",
    }),
    canSend() {
      return (
        this.values.plannedDate &&
        this.values.unitLoad &&
        this.values.reference &&
        this.values.enteredBy?.name &&
        this.values.enteredBy?.email &&
        this.values.items?.length
      );
    },
    UnitLoad() {
      return UnitLoad;
    },
    ...mapGetters("authorisation", ["client", "isClient", "profile"]),
    isUpdateForm(): boolean {
      return this.$route.params.deliveryId !== undefined;
    },
    clientId(): number {
      if (this.isUpdateForm) {
        return parseInt(this.$route.params.clientId as string);
      }
      return this.client?.id;
    },
  },
  watch: {
    "$route.params.deliveryId": {
      immediate: true,
      async handler(id: number): Promise<void> {
        if (!id) {
          this.isLoading = false;
          return;
        }
        try {
          this.isLoading = true;
          const response = await conceptDeliveryShow({
            deliveryId: id,
            clientId: this.clientId,
          });
          if (response.data.validationErrors) {
            setTimeout(() => {
              Object.keys(response.data.validationErrors.errors).forEach(
                (key) => {
                  this.addValidationErrorToStore({
                    key,
                    message: response.data.validationErrors.errors[key][0],
                  });
                }
              );
              (this.$refs.form as any).$refs.form.validate();
            }, 500);
          }

          this.resetDelivery();
          this.values = {
            ...this.values,
            ...response.data.data,
          };
          this.$nextTick(() => (this.isFormDirty = false));
        } catch (error: AxiosError | unknown) {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            this.$router.push({ name: "notFound" });
            return;
          }
          throw error;
        }

        this.isLoading = false;
      },
    },
    values: {
      deep: true,
      handler(): void {
        this.isFormDirty = true;
      },
    },
    "client.id": {
      handler(newValue: number, oldValue: number): void {
        if (!oldValue) return;
        this.$router.push({
          name: "delivery.concept",
        });
      },
    },
  },
  created() {
    this.resetDelivery();
    this.values.enteredBy = {
      name: this.profile.name,
      email: this.profile.email,
    };
  },
  methods: {
    ...mapMutations("error", {
      addValidationErrorToStore: "add",
    }),
    resetDelivery() {
      this.values = {
        reference: "",
        plannedDate: "",
        unitLoad: undefined,
        unitLoadInfo: undefined,
        enteredBy: {
          name: "",
          email: "",
        },
        items: [],
      };
    },
    send(): void | Promise<AxiosResponse<DeliverySingle>> {
      if (!this.values) return;
      if (this.values.unitLoad === UnitLoad.Colli) {
        this.values.unitLoadInfo = undefined;
      }

      this.isFormDirty = false;

      if (this.isUpdateForm) {
        return this.updateFormSend();
      }
      return this.createFormSend();
    },
    createFormSend(): Promise<AxiosResponse> {
      return send({
        delivery: this.values,
        clientId: this.clientId,
      });
    },
    updateFormSend(): Promise<AxiosResponse> {
      return sendById({
        delivery: this.values,
        deliveryId: parseInt(this.$route.params.deliveryId),
        clientId: this.clientId,
      });
    },
    async saveConcept() {
      if (this.values.unitLoad === UnitLoad.Colli) {
        this.values.unitLoadInfo = undefined;
      }
      if (!this.canSend) {
        eventBus.$emit("snackbar", {
          color: "error",
          text: this.$t("product.messages.notAllFields"),
        });

        (this.$refs.form as any).$refs.form.validate();
        return;
      }
      this.isLoadingSave = true;
      try {
        if (!this.values) {
          return;
        }
        await this.ifUpdateFormSaveConcept();
        await this.ifCreateFormSaveConcept();
        eventBus.$emit("snackbar", this.$t("global.saveSuccess"));
        this.isFormDirty = false;
        this.redirectToOverview();
      } catch (e) {
        eventBus.$emit("snackbar", {
          color: "error",
          text: this.$t("global.error"),
        });
        throw e;
      }
      this.isLoadingSave = false;
    },
    async ifUpdateFormSaveConcept() {
      if (this.isUpdateForm) {
        await conceptSaveById({
          delivery: this.values,
          clientId: this.clientId,
          deliveryId: parseInt(this.$route.params.deliveryId),
        });
      }
    },
    async ifCreateFormSaveConcept() {
      if (!this.isUpdateForm) {
        await conceptSave({
          delivery: this.values,
          clientId: this.clientId,
        });
      }
    },
    addProduct() {
      if (!this.values) return;
      if (!this.values.items) {
        this.values.items = [];
      }

      const existingItem = this.values.items.findIndex(
        (item) => item.articleNumber === this.productToAdd?.articleNumber
      );

      if (existingItem > -1) {
        eventBus.$emit("snackbar", {
          text: this.$t("delivery.messages.productAlreadyExist"),
          color: "error",
        });
        return;
      }

      this.values.items.push({
        articleName: this.productToAdd?.name || "",
        articleNumber: this.productToAdd?.articleNumber || "",
        quantity: 1,
        unit: this.productToAdd?.units?.[0].name || "",
        units: this.productToAdd?.units,
      });
      this.productToAdd = null;
    },
    removeProduct(index: number) {
      if (!this.values.items?.length) return;
      this.values.items.splice(index, 1);
    },
    handleSuccess() {
      eventBus.$emit("snackbar", this.$t("delivery.messages.sendSuccess"));
      this.redirectToOverview();
    },
    redirectToOverview() {
      this.$router.push({
        name: "delivery.concept",
      });
    },
  },
});
