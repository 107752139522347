
import KPaginatedAutocomplete from "@/components/crud/fields/KPaginatedAutocomplete.vue";
import Vue, { VueConstructor } from "vue";
import { productAutocomplete } from "@/modules/delivery/api/productAutocomplete";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        autocomplete: {
          refresh: () => Promise<unknown>;
        };
      };
    }
  >
).extend({
  name: "ProductAutocomplete",
  components: { KPaginatedAutocomplete },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    clientId: {
      handler() {
        this.$refs.autocomplete.refresh();
      },
    },
  },
  methods: {
    productAutocomplete(data: any) {
      return productAutocomplete(this.clientId, data);
    },
  },
});
